import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Sidebar from '../components/Sidebar/Sidebar'
import { Container, Row, Col } from 'react-bootstrap'
import IndexForm from '../components/IndexForm/IndexForm'     
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import '../styles/templates/service-page-template.scss'
import '../styles/pages/about.scss'

function AboutPage({data, pageContext}) {
    const {
      breadcrumb: { crumbs },
    } = pageContext

    const title = 'О компании';
    const extraItem = {
      title: title,
      slug: 'about'
    }
    
    return (
        <Layout>
            <Seo title={title}/>
            <Container className='content'  fluid={'xl'}>
                <Row  className="section-with-sidebar">
                    <Sidebar 
                      sectionNavigation = {data.allContentfulAboutSection.nodes}
                      extraItem = {extraItem}
                      currentSection = 'about'
                    />
                    <Col className="page-with-sidebar" xs={12} md={8} lg={9}>
                      <Breadcrumb
                          crumbs={crumbs}
                          crumbLabel={title}
                        />
                        <h1 className="service-page--title">{title}</h1>
                        <div className='about-section--body' 
                          dangerouslySetInnerHTML={{__html: data.contentfulAboutSection.body.childMarkdownRemark.html}}
                          />
                    </Col>
                </Row>
            </Container>
            <IndexForm mail={data.contentfulCompanyContacts.mail}/>
        </Layout>
    )
}

export default AboutPage

export const AboutPageQuery = graphql`
query aboutPageQuery {
  contentfulAboutSection(title: {eq: "О производстве"}) {
    body {
      childMarkdownRemark {
        html
      }
    }
  }
  allContentfulAboutSection(filter: {title: {ne: "О производстве"}}, sort: {fields: order, order: ASC}) {
    nodes {
      title
      slug
    }
  }
   contentfulCompanyContacts(title: {eq: "Дерево Желаний"}) {
       mail
     }
  }
`